import React from 'react'
import Image from '../components/Image'
import { Carousel } from '../components/Carousel'
import './Gallery.scss'

export const Gallery = ({ id, gallery }) => {
  return (
    <div className="gallery">
      {gallery && (
        <Carousel
          infinite
          autoPlay
          duration={500}
          autoPlayInterval={2500}
          id={id}
          responsive={{
            0: {
              items: 1,
              itemsFit: 'contain',
            },
            1024: {
              items: 2,
              itemsFit: 'contain',
            },
          }}
        >
          {gallery.map((slide, index) => (
            <div className="slide" key={index}>
              <Image image={slide} position="relative" />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  )
}
